export const localizeDataNodes = (data, lang) => {
  let localizeDataNodesObject = {}
  Object.keys(data).forEach(key => {
    const items = data[key].nodes
    localizeDataNodesObject = {
      ...localizeDataNodesObject,
      [key]: Object.entries(items).map(([_, value]) => value[lang]),
    }
  })
  return localizeDataNodesObject
}

export const localizeStaticNodes = (data, lang) => {
  const localizeDataNodesArray = [];
  data.forEach(item => {
  let localizeDataNodesObject = {}
    Object.keys(item).forEach(key => {
        localizeDataNodesObject = {
          ...localizeDataNodesObject,
          [key.replace(`_${lang}`, '')]: item[key],
        }
    })
    localizeDataNodesArray.push(localizeDataNodesObject);
  })
  return localizeDataNodesArray
}
