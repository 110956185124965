/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useMemo } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import PropTypes from "prop-types"
import MainMenu from "../../components/MainMenu/MainMenu"
import Footer from "../../components/Footer/Footer"
import "./Layout.scss"
import { useStaticQuery, graphql } from "gatsby"
import { MENUS_IDS } from "../../constants"
import { LanguageProvider } from "../../contexts/languageContext"
import { localizeDataNodes } from "../../utils/localizeDataNodes"

const LayoutInnerContent = ({ children, location, lang, companies }) => {
  const data = useStaticQuery(graphql`
    query pagesAndCompaniesQuery {
      allDzemoApiPages(sort: { order: ASC, fields: en___weight }) {
        nodes {
          id
          en {
            id
            name
            template
            path
            metadescription
            weight
            subpages {
              id
              name
              name_de
              path
              path_de
              weight
              subpages {
                id
                name
                name_de
                path
                path_de
                weight
              }
              subpages_generals {
                id
                name
                name_de
                route
                route_de
                weight
              }
            }
            subpages_generals {
              id
              name
              name_de
              route
              route_de
              weight
            }
            menu {
              id
            }
          }
          de {
            id
            name
            template
            path
            metadescription
            weight
            subpages {
              id
              name
              name_de
              path
              path_de
              weight
              subpages {
                id
                name
                name_de
                path
                path_de
                weight
              }
              subpages_generals {
                id
                name
                name_de
                route
                route_de
                weight
              }
            }
            subpages_generals {
              id
              name
              name_de
              route
              route_de
              weight
            }
            menu {
              id
            }
          }
        }
      }
      allDzemoApiCompanies(limit: 1) {
        nodes {
          id
          en {
            address
            email
            facebook
            id
            instagram
            linkedin
            telephone
            twitter
            xing
            image {
              alternativeText
              url
              height
              width
            }
          }
          de {
            address
            email
            facebook
            id
            instagram
            linkedin
            telephone
            twitter
            xing
            image {
              alternativeText
              url
              height
              width
            }
          }
        }
      }
    }
  `)

  const { allDzemoApiPages, allDzemoApiCompanies } = useMemo(
    () => localizeDataNodes(data, lang),
    [data, lang]
  )

  return (
    <div className="layout">
      <MainMenu
        location={location}
        data={allDzemoApiPages.filter(
          ({ menu }) => menu && menu.id === MENUS_IDS.mainMenuId
        )}
        image={allDzemoApiCompanies[0].image[0]}
        logoUrl={companies ? companies[0].node[lang].staticImage.publicUrl : ''}
      />

      <div>
        <main>{children}</main>
      </div>
      <Footer
        location={location}
        company={companies ? companies[0].node[lang] : []}
        data={allDzemoApiPages.filter(
          ({ menu }) => menu && menu.id === MENUS_IDS.footerMenuId
        )}
      />
    </div>
  )
}

const Layout = ({ children, location, lang, routePrefix, pageData, companies }) => {
  return (
    <LanguageProvider lang={lang} pageData={pageData} routePrefix={routePrefix}>
      <LayoutInnerContent location={location} lang={lang} companies={companies}>
        {children}
      </LayoutInnerContent>
    </LanguageProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
