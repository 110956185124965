export const MESSAGE_TYPES = {
  success: "success",
  info: "info",
  error: "error",
}

export const MENUS_IDS = {
  mainMenuId: 1,
  footerMenuId: 2,
  engineeringMenuId: 5,
  recruitingMenuId: 6,
}

export const LANGUAGES = {
  ENGLISH: {
    key: "",
    label: "EN",
    fullTitle: "English",
    browser: [
      "en",
      "en-US",
      "en-EG",
      "en-AU",
      "en-GB",
      "en-CA",
      "en-NZ",
      "en-IE",
      "en-ZA",
      "en-JM",
      "en-BZ",
      "en-TT",
    ],
  },
  GERMAN: {
    key: "de",
    label: "DE",
    fullTitle: "German",
    browser: ["de", "de-CH", "de-AT", "de-LU", "de-LI"],
  },
}
