import React, { useState } from "react"
import { LANGUAGES } from "../constants"
import { useGoogleSheet } from "../hooks/useGoogleSheet"
import { navigate } from "gatsby"

const LanguageContext = React.createContext()

const LanguageProvider = ({ children, pageData, routePrefix, lang }) => {
  const lastSelectedLanguage =
    lang === LANGUAGES.GERMAN.label.toLowerCase()
      ? LANGUAGES.GERMAN
      : LANGUAGES.ENGLISH

  const googleSheetData = useGoogleSheet()

  // const getLanguageBasedOnBrowser = browserLangKey => {
  //   Object.keys(LANGUAGES).forEach(key => {
  //     const currentKeyLanguage = LANGUAGES[key]

  //     if (
  //       currentKeyLanguage.browser.find(
  //         langKey => langKey === browserLangKey
  //       ) &&
  //       language !== currentKeyLanguage
  //     ) {
  //       return currentKeyLanguage
  //     }
  //   })
  // }

  // const languageBasedOnBrowser = getLanguageBasedOnBrowser(
  //   detectBrowserLanguage()
  // )

  const [language, setLanguage] = useState(
    lastSelectedLanguage ? lastSelectedLanguage : LANGUAGES.ENGLISH
  )

  const handleLanguageChange = language => {
    setLanguage(language)

    const isPostPage = pageData && pageData[language.label.toLowerCase()].route
    const pageRoute = pageData
      ? !isPostPage
        ? pageData[language.label.toLowerCase()].path
        : pageData[language.label.toLowerCase()].route
      : ""

    const prefix = isPostPage ? routePrefix : ""

    if (pageData) {
      navigate(
        `${
          language.label === LANGUAGES.GERMAN.label
            ? `/${LANGUAGES.GERMAN.label.toLowerCase()}${prefix}${pageRoute}`
            : `${prefix}${pageRoute}`
        }`,
        { replace: true }
      )
    }
  }

  return (
    <LanguageContext.Provider
      value={[language, handleLanguageChange, googleSheetData]}
    >
      {children}
    </LanguageContext.Provider>
  )
}
export { LanguageContext, LanguageProvider }
