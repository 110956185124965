import React, { useContext, useReducer, useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import "./Footer.scss"
import { Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { company as socialIcons } from "../../data/footer-data"
import parse from "html-react-parser"
import { LanguageContext } from "../../contexts/languageContext"
import { LANGUAGES } from "../../constants"
import { getLocalizedField } from "../../utils/getLocalizedField"

const API_URL = process.env.GATSBY_API_URL
const currentYear = new Date().getFullYear()
const BREAK_POINTS = {
  sm: 576,
  md: 768,
  lg: 991,
  xl: 1200,
}

/* A custom footer component.
@param company - company - object {address: ..., telephone: ..., email: ..., linkedin: ..., instagram: ..., facebook: ..., xing: ..., twitter: ...}
@param data - Object, eg. {pages: [{name: 'Contacts', subpages: [{name: ..., link: ...}, {name: ..., link: ..., subpages: [{name: ..., link: ...}, {...}]}]}
*/
const Footer = ({ company, location, data }) => {
  const { linkedin, facebook, xing, twitter } = socialIcons
  const icons = { linkedin, facebook, xing, twitter }
  const [width, setWidth] = useState(typeof window !== `undefined` ? window.innerWidth : 0)

  const {
    address,
    email,
    facebook: facebookUrl,
    // instagram: instagramUrl,
    linkedin: linkedinUrl,
    telephone,
    twitter: twitterUrl,
    xing: xingUrl,
    image,
    staticImage
  } = company

  let socialMediaLinks = {
    linkedin: linkedinUrl,
    // instagram: instagramUrl,
    facebook: facebookUrl,
    twitter: twitterUrl,
    xing: xingUrl,
  }
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const resizeWindow = () => setWidth(window.innerWidth)

      window.addEventListener("resize", resizeWindow)

      return () => window.removeEventListener("resize", resizeWindow)
    }
  }, [])
  const isMobileMenu = width <= BREAK_POINTS.lg
  const ids = data.filter(item => item.name.includes('company'))[0] && data.filter(item => item.name.includes('company'))[0].subpages.map(item => item.name)

  let initialStateOfDropdown = []

  useEffect(() => {
    dispatchDropdownState({
      type: "refresh",
    })
  }, [])

  const showDropdownReducer = (state, action) => {
    let updatedState = []
    switch (action.type) {
      case "show":
        for (const [key, value] of Object.entries(state)) {
          if (key === action.id) {
            updatedState[key] = { ...value, show: true }
            continue
          }
          updatedState[key] = value
        }
        return updatedState
      case "hide":
        for (const [key, value] of Object.entries(state)) {
          if (key === action.id) {
            updatedState[key] = { ...value, show: false }
            continue
          }
          updatedState[key] = value
        }
        return updatedState
      case "refresh":
        ids.forEach(id => {
          updatedState[id] = { show: false }
        })
        return updatedState
      default:
        throw new Error()
    }
  }

  const [dropdownState, dispatchDropdownState] = useReducer(
    showDropdownReducer,
    initialStateOfDropdown
  )

  const onDropdownItemClick = pathTo => {
    navigate(pathTo)
  }

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]

  const contactButtonTextEnglish = "contact"
  const contactButtonTextGerman = "kontakt"

  const contactPage = data.find(
    item =>
      item.name.toLowerCase().includes(contactButtonTextEnglish) ||
      item.name.toLowerCase().includes(contactButtonTextGerman)
  )

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  let staticLogo = staticImage && staticImage.publicUrl !== '' ? `${staticImage.publicUrl}` : `${API_URL}${image && image[0].url}`

  return (
    <div className="footer">
      <div className="footer-container">
        <section className="footer-address">
          <div className="footer-logo">
            {image && (
              <img
                src={staticLogo}
                width={image[0].width}
                height={image[0].height}
                alt={image[0].alternativeText}
              />
            )}
          </div>
          <div className="item-text">
            <p>{address && parse(address)}</p>
          </div>
        </section>
        <section className="footer-columns">
          {data.map(({ name, subpages, id }) => {
            const isCurrentPageContact = name === contactPage?.name
            return (
              <div key={id} className="footer-column">
                <p className="column-title">{name}</p>
                <ul className="column-list">
                  {isCurrentPageContact && (
                    <>
                      <li className="column-list-item">
                        <span className="item-text">{telephone}</span>
                      </li>

                      <li className="column-list-item">
                        <a className="item-text" href={`mailto:${email}`}>
                          {email}
                        </a>
                      </li>
                    </>
                  )}
                  {subpages &&
                    subpages
                      .sort((a, b) => (a.weight < b.weight ? -1 : 1))
                      .map(subpageItem => {
                        const { id: subpageId } = subpageItem
                        const localizedName =
                          subpageItem[
                            getLocalizedField("name", currentLanguage.label)
                          ]
                        const localizedPath =
                          subpageItem[
                            getLocalizedField("path", currentLanguage.label)
                          ]
                          let link = [];
                          if (subpageItem.subpages && subpageItem.subpages.length > 0 && subpageItem.name.toLowerCase().includes('engineering')) {
                            let item = subpageItem
                            if (item && dropdownState[item.name]) {
                              const { show } = dropdownState[item.name]
                              let dynamicProps = {};
                              if (!isMobileMenu) {
                                dynamicProps = {
                                  show: show,
                                }
                              }
                              else {
                                dynamicProps = {};
                              }
          
                              if (
                                item.subpages.length > 0 ||
                                item.subpages_generals.length > 0
                              ) {
                                const { path } = item
                                const combinedSubpages = [
                                  ...item.subpages,
                                  ...item.subpages_generals,
                                ].sort((a, b) => a.weight - b.weight)
          
                                const pathWithPrefix = path => `${routePrefix}${path}`
          
                                link = (
                                  <li key={subpageId} className="column-list-item">
                                  <Dropdown
                                    as={ButtonGroup}
                                    key={item.id}
                                    onMouseOver={
                                      !isMobileMenu
                                        ? () => {
                                            dispatchDropdownState({
                                              type: "show",
                                              id: item.name,
                                            })
                                          }
                                        : () => {}
                                    }
                                    onMouseLeave={
                                      !isMobileMenu
                                        ? () =>
                                            dispatchDropdownState({
                                              type: "hide",
                                              id: item.name,
                                            })
                                        : () => {}
                                    }
                                  >
                                    <div className="dropdown-mainlink-with-toggler">
                                      <Button
                                        title={item.name}
                                        variant="transparent"
                                        key={item.id}
                                        onClick={() =>
                                          onDropdownItemClick(pathWithPrefix(path))
                                        }
                                        className="dropdown-mainlink-button"
                                        active={
                                          location?.pathname === pathWithPrefix(path) ||
                                          combinedSubpages.some(subpage =>
                                            subpage.path
                                              ? pathWithPrefix(subpage.path) ===
                                                location?.pathname
                                              : pathWithPrefix(subpage.route) ===
                                                location?.pathname
                                          )
                                        }
                                      >
                                        {item.name}
                                      </Button>
          
                                      <Dropdown.Toggle
                                        split
                                        variant="transparent"
                                        id="dropdown-split-basic"
                                      />
                                    </div>
                                    <Dropdown.Menu
                                      id={`collapsible-nav-dropdown-${item.name}`}
                                      {...dynamicProps}
                                    >
                                      {combinedSubpages.map(subpageItem => {
                                        const { id } = subpageItem
                                        const localizedName =
                                          subpageItem[
                                            getLocalizedField(
                                              "name",
                                              currentLanguage.label
                                            )
                                          ]
                                        const localizedPath = subpageItem.path
                                          ? subpageItem[
                                              getLocalizedField(
                                                "path",
                                                currentLanguage.label
                                              )
                                            ]
                                          : subpageItem[
                                              getLocalizedField(
                                                "route",
                                                currentLanguage.label
                                              )
                                            ]
          
                                        return (
                                          <Dropdown.Item
                                            title={subpageItem.name}
                                            as={Link}
                                            key={`${localizedName}_${id}`}
                                            to={pathWithPrefix(localizedPath)}
                                            active={
                                              location?.pathname ===
                                              pathWithPrefix(localizedPath)
                                            }
                                          >
                                            {localizedName}
                                          </Dropdown.Item>
                                        )
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  </li>
                                )
                              }
                            } else {
                              return <React.Fragment key={0} />
                            }
                          }
                          else {
                            link = (
                              <li key={subpageId} className="column-list-item">
                              <Link
                                className="item-text"
                                to={`${routePrefix}${localizedPath}`}
                              >
                                {localizedName}
                              </Link>
                            </li>
                            );
                          }

                        return (
                         link
                        )
                      })}
                </ul>
              </div>
            )
          })}
        </section>
      </div>
      <div className="social-media-links-container">
        {/* Social Media Column */}
        <div className="year">
          <div className="item-text">&copy;&nbsp;{currentYear} Dzemo </div>
        </div>
        <div className="social-media-links">
          {Object.keys(icons).map(key => {
            const iconObject = icons[key]
            return (
              <a
                href={socialMediaLinks[key]}
                key={key}
                title={key}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <iconObject.icon />
                </span>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Footer
