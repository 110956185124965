import React, { useContext, useEffect, useReducer, useState } from "react"
import { Navbar, Nav, Dropdown, ButtonGroup, Button } from "react-bootstrap"
import { Link, navigate } from "gatsby"
import "./MainMenu.scss"
import { LanguageContext } from "../../contexts/languageContext"
import { LANGUAGES } from "../../constants"
import { getLocalizedField } from "../../utils/getLocalizedField"

const API_URL = process.env.GATSBY_API_URL
const BREAK_POINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const burgerMenuCollapseBreakpoint = Object.keys(BREAK_POINTS).find(
  key => key === "xl"
)

/* A custom menu component.
@param data - Object, eg. {data: [{name: 'Home', link: 'https://domain/'}, {name: ..., link: ..., subpages: [{name: ..., link: ...}, {...}]}]}
*/
const MainMenu = ({ data, location, image, logoUrl }) => {
  const [width, setWidth] = useState()

  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const setCurrentLanguage = languageContext[1]

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const resizeWindow = () => setWidth(window.innerWidth)

      window.addEventListener("resize", resizeWindow)

      return () => window.removeEventListener("resize", resizeWindow)
    }
  }, [])

  const isMobileMenu = width <= BREAK_POINTS.xl

  const ids = data.map(item => item.name)

  let initialStateOfDropdown = []

  useEffect(() => {
    dispatchDropdownState({
      type: "refresh",
    })
  }, [])

  const showDropdownReducer = (state, action) => {
    let updatedState = []
    switch (action.type) {
      case "show":
        for (const [key, value] of Object.entries(state)) {
          if (key === action.id) {
            updatedState[key] = { ...value, show: true }
            continue
          }
          updatedState[key] = value
        }
        return updatedState
      case "hide":
        for (const [key, value] of Object.entries(state)) {
          if (key === action.id) {
            updatedState[key] = { ...value, show: false }
            continue
          }
          updatedState[key] = value
        }
        return updatedState
      case "refresh":
        ids.forEach(id => {
          updatedState[id] = { show: false }
        })
        return updatedState
      default:
        throw new Error()
    }
  }

  const [dropdownState, dispatchDropdownState] = useReducer(
    showDropdownReducer,
    initialStateOfDropdown
  )

  const switchToLanguageObject =
    LANGUAGES[
      Object.keys(LANGUAGES).find(
        languageName => LANGUAGES[languageName].label !== currentLanguage?.label
      )
    ]

  const handleLanguageSwitch = language => {
    setCurrentLanguage(language)
  }

  const languageSwitchButton = (
    <button
      onClick={() => handleLanguageSwitch(switchToLanguageObject)}
      type="button"
      className="btn btn-outline-dark btn-small lng-btn"
    >
      {switchToLanguageObject.label}
    </button>
  )

  const contactButtonTextEnglish = "contact"
  const contactButtonTextGerman = "kontakt"

  const contactPage = data.find(
    item =>
      item.name.toLowerCase().includes(contactButtonTextEnglish) ||
      item.name.toLowerCase().includes(contactButtonTextGerman)
  )

  const homePage = data.find(
    item =>
      item.path.toLowerCase() === "/" ||
      item.name.toLowerCase().includes("home") ||
      item.name.toLowerCase().includes("startseite")
  )

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  const onDropdownItemClick = pathTo => {
    navigate(pathTo)
  }

  let staticLogo = logoUrl && logoUrl !== '' ? `${logoUrl}` : `${API_URL}${image.url}`

  return (
    <div className="container MainMenu">
      <Navbar collapseOnSelect expand={burgerMenuCollapseBreakpoint}>
        <Navbar.Brand as={Link} to={`${routePrefix}${homePage?.path}`}>
          <img
            src={staticLogo}
            width={image.width}
            height={image.height}
            alt={image.alternativeText}
          />
        </Navbar.Brand>
        {languageSwitchButton}
        <div className="navbar-nav-items-container">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              {data
                .filter(
                  item =>
                    !item.name
                      .toLowerCase()
                      .includes(contactButtonTextEnglish) &&
                    !item.name.toLowerCase().includes(contactButtonTextGerman)
                )
                .map((item, index) => {
                  if (item && dropdownState[item.name]) {
                    const { show } = dropdownState[item.name]

                    if (
                      item.subpages.length > 0 ||
                      item.subpages_generals.length > 0
                    ) {
                      const { path } = item
                      const combinedSubpages = [
                        ...item.subpages,
                        ...item.subpages_generals,
                      ].sort((a, b) => a.weight - b.weight)

                      const pathWithPrefix = path => `${routePrefix}${path}`

                      return (
                        <Dropdown
                          as={ButtonGroup}
                          key={item.id}
                          onMouseOver={
                            !isMobileMenu
                              ? () => {
                                  dispatchDropdownState({
                                    type: "show",
                                    id: item.name,
                                  })
                                }
                              : () => {}
                          }
                          onMouseLeave={
                            !isMobileMenu
                              ? () =>
                                  dispatchDropdownState({
                                    type: "hide",
                                    id: item.name,
                                  })
                              : () => {}
                          }
                        >
                          <div className="dropdown-mainlink-with-toggler">
                            <Button
                              title={item.name}
                              variant="transparent"
                              key={item.id}
                              onClick={() =>
                                onDropdownItemClick(pathWithPrefix(path))
                              }
                              className="dropdown-mainlink-button"
                              active={
                                location?.pathname === pathWithPrefix(path) ||
                                combinedSubpages.some(subpage =>
                                  subpage.path
                                    ? pathWithPrefix(subpage.path) ===
                                      location?.pathname
                                    : pathWithPrefix(subpage.route) ===
                                      location?.pathname
                                )
                              }
                            >
                              {item.name}
                            </Button>

                            <Dropdown.Toggle
                              split
                              variant="transparent"
                              id="dropdown-split-basic"
                            />
                          </div>

                          <Dropdown.Menu
                            id={`collapsible-nav-dropdown-${item.name}`}
                            show={show}
                          >
                            {combinedSubpages.map(subpageItem => {
                              const { id } = subpageItem
                              const localizedName =
                                subpageItem[
                                  getLocalizedField(
                                    "name",
                                    currentLanguage.label
                                  )
                                ]
                              const localizedPath = subpageItem.path
                                ? subpageItem[
                                    getLocalizedField(
                                      "path",
                                      currentLanguage.label
                                    )
                                  ]
                                : subpageItem[
                                    getLocalizedField(
                                      "route",
                                      currentLanguage.label
                                    )
                                  ]

                              return (
                                <Dropdown.Item
                                  title={subpageItem.name}
                                  as={Link}
                                  key={`${localizedName}_${id}`}
                                  to={pathWithPrefix(localizedPath)}
                                  active={
                                    location?.pathname ===
                                    pathWithPrefix(localizedPath)
                                  }
                                >
                                  {localizedName}
                                </Dropdown.Item>
                              )
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )
                    }

                    return (
                      <Nav.Link
                        as={Link}
                        active={
                          location?.pathname === `${routePrefix}${item.path}` ||
                          location?.pathname.match(`^${item.path}(/.*)?$`)
                        }
                        key={item.name}
                        to={`${routePrefix}${item.path}`}
                      >
                        {item.name}
                      </Nav.Link>
                    )
                  } else {
                    return <React.Fragment key={index} />
                  }
                })}
            </Nav>

            <div className="navbar-buttons-container">
              {languageSwitchButton}
              <Link
                to={`${routePrefix}${contactPage?.path}`}
                className="btn btn-outline-danger"
              >
                {contactPage?.name}
              </Link>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  )
}
export default MainMenu
