import React, { useMemo } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import PropTypes from "prop-types"
import "./EngineeringLayout.scss"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "./../"
import SubMenu from "../../components/SubMenu/SubMenu"
import { MENUS_IDS } from "../../constants"
import { localizeDataNodes } from "../../utils/localizeDataNodes"

const EngineeringLayout = ({ children, lang, location, pageData, companies }) => {
  const data = useStaticQuery(graphql`
    query pagesQuery {
      allDzemoApiPages(sort: { order: ASC, fields: en___weight }) {
        nodes {
          en {
            id
            name
            template
            path
            metadescription
            weight
            subpages {
              id
              name
              path
              weight
              icon {
                url
                width
                height
                hash
                alternativeText
              }
            }
            menu {
              id
            }
          }
          de {
            id
            name
            template
            path
            metadescription
            weight
            subpages {
              id
              name_de
              path_de
              weight
              icon {
                url
                width
                height
                hash
                alternativeText
              }
            }
            menu {
              id
            }
          }
        }
      }
    }
  `)

  const { allDzemoApiPages } = useMemo(() => localizeDataNodes(data, lang), [
    data,
    lang,
  ])

  const engineeringSubPages =
    allDzemoApiPages
      .filter(({ menu }) => menu?.id === MENUS_IDS.engineeringMenuId)
      .sort((a, b) => (a.weight < b.weight ? -1 : 1)) ?? []

  return (
    <div className="engineering-page-layout">
      <Layout location={location} lang={lang} pageData={pageData} companies={companies}>
        <SubMenu data={engineeringSubPages} location={location} />
        {children}
      </Layout>
    </div>
  )
}

EngineeringLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EngineeringLayout
