export const translateText = (text, languageKey, data) => {
  const translatedText = data.find(item => {
    const previousLanguage = Object.keys(item).find(key => key !== languageKey)
    return (
      item[previousLanguage].toLowerCase() === text?.toLowerCase() &&
      item[languageKey]
    )
  })
  return translatedText ? translatedText[languageKey] : text
}
