import { useEffect, useState } from "react"
import Tabletop from "tabletop"

export const useGoogleSheet = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    let isMounted = true
    const isInitialMount = data.length === 0

    if (isInitialMount) {
      Tabletop.init({
        key: process.env.GATSBY_GOOGLE_SHEET_DOC_KEY,
        simpleSheet: true,
      })
        .then(data => {
          isMounted && setData(data)
        })
        .catch(err => console.warn(err))
    }
    return () => (isMounted = false)
  }, [data.length])
  return data
}
