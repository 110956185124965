import { ReactComponent as LinkedInIcon } from "../assets/icons/linked-in-icon.svg"
import { ReactComponent as InstagramIcon } from "../assets/icons/instagram-icon.svg"
import { ReactComponent as FacebookIcon } from "../assets/icons/fb-icon.svg"
import { ReactComponent as XingIconIcon } from "../assets/icons/xing-icon.svg"
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter-icon.svg"


const user = "hello"
const domain = "dzemo.com"

const generatedEmail = `${user}@${domain}`

export const footerLinks = {
  pages: [
    {
      name: "Contacts",
      subpages: [
        {
          name: "Contact us",
          link: "/contact-us",
        },
      ],
    },
    {
      name: "Company",
      subpages: [
        { name: "Engineering", link: "/engineering" },
        { name: "Home", link: "/home" },
        { name: "Recruitment", link: "/recruiting" },
        { name: "Company", link: "/company" },
      ],
    },
    {
      name: "Pages",
      subpages: [
        { name: "Blog", link: "/blog" },
        { name: "Testimonials", link: "/testimonials" },
        { name: "Imprint", link: "/imprint" },
        { name: "Projects", link: "/projects" },
      ],
    },
  ],
}

export const company = {
  address: "Wicherstr. 52,  10439 Berlin, Germany",
  telephone: "+49 221 25912509",
  email: generatedEmail,
  linkedin: {
    link: "https://www.linkedin.com/company/dzemo/",
    icon: LinkedInIcon,
  },
  instagram: {
    link: "https://www.instagram.com/dzemo/",
    icon: InstagramIcon,
    title: "instagram",
  },
  facebook: {
    link: "https://www.facebook.com/dzemo/",
    icon: FacebookIcon,
    title: "facebook",
  },
  twitter: {
    link: "https://www.twitter.com/pages/dzemo",
    icon: TwitterIcon,
    title: "twitter",
  },
  xing: {
    link: "https://www.xing.com/pages/deutschebahn",
    icon: XingIconIcon,
    title: "xing",
  },

}
