import { Link } from "gatsby"
import React, { useContext } from "react"
import { LANGUAGES } from "../../constants"
import { LanguageContext } from "../../contexts/languageContext"
import { getLocalizedField } from "../../utils/getLocalizedField"
import "./SubMenu.scss"

const SubMenu = ({ data, location }) => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  return (
    <ul className="sub-menu-container">
      {data.map(item => {
        const { id } = item
        const localizedName =
          item[getLocalizedField("name", currentLanguage.label)] || item.name
        const localizedPath =
          item[getLocalizedField("path", currentLanguage.label)] ||
          item.path ||
          item.route

        return (
          <Link
            key={id}
            className={
              location?.pathname === `${routePrefix}${localizedPath}`
                ? "active"
                : ""
            }
            to={`${routePrefix}${localizedPath}`}
          >
            {localizedName}
          </Link>
        )
      })}
    </ul>
  )
}

export default SubMenu
